export const DEFAULT_PLAYER_ID = 'c7fa8467-544d-45a6-818f-c28fa19be069';

export const uiOptions = {
  NO_FULLSCREEN: 1, // Hide fullscreen button
  NO_VOLUME_CONTROL: 2, // Hide volume control bar
  NO_MUTE: 4, // Hide mute button
  LOGO_ON_RIGHT: 8, // Show custom logo in right corner of the player
  USE_DRAG_HANDLE: 16, // Add a round scrubbing handle to the timeline
  USE_PLAY_2: 32, // Use alternate play (outlined) button in the center of the player
  USE_PLAY_3: 64, // Use alternate play button (circled) in the center of the player
  USE_THIN_CONTROLBAR: 128,
  NO_TITLE: 256, // Don't show clip title (since v1.12.0)
  NO_DESCRIPTION: 512, // Don't show clip description metadata (since v1.12.0)
  NO_CONTROLS: 1024, // Disable the complete control bar
  NO_DURATION: 2048, // Disable display of duration
  NO_HEADER: 4096, // Disable the header which holds the logo, as well as the share, cast and fullscreen buttons
};

export const startQualityOptions = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 4,
};

// https://docs.flowplayer.com/player/configuration#autoplay-configuration
export const autoplayOptions = {
  false: 0,
  true: 1,
  trueIfInteractive: 2,
};
const inversedAutoplayOptions = {
  0: 'false',
  1: 'true',
  2: 'trueIfInteractive',
};
const validAutoplayValues = Object.keys(autoplayOptions).concat(
  Object.keys(inversedAutoplayOptions)
);

const uiCode = (optionsArray) =>
  optionsArray
    .replace(/\s+/g, '')
    .split(',')
    .map((o) => uiOptions[o])
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

const parseUiOverrides = (options) =>
  !options || !options.length ? null : uiCode(options);

const toUiOverrides = (code) =>
  Object.entries(uiOptions)
    .map(([key, value]) => ((code & value) === value ? key : null))
    .filter(Boolean)
    .join(',');

const parseStartQuality = (option) => {
  if (option.split(',').length > 1) {
    throw new Error('invalid startQuality option');
  }
  return !option ? null : startQualityOptions[option];
};

const toStartQuality = (code) => {
  if (!code) {
    return null;
  }
  const bitmask = Object.entries(startQualityOptions)
    .map(([key, value]) => ({ key, value }))
    .find(({ value }) => {
      return value === code;
    });
  if (!bitmask) {
    throw new Error('invalid startQuality option');
  }
  return bitmask.key;
};

export class NextVideo {
  constructor({ mediaid, ...attributes }, staticProperties) {
    if (!mediaid) {
      throw new Error('mediaid is required in nextVideo');
    }
    this.staticProperties = staticProperties;
    this.mediaId = staticProperties.mediaId.type.fromString(mediaid);
    const entries = Object.entries(staticProperties);
    Object.entries(attributes).forEach(([attributeKey, attributeValue]) => {
      const property = entries.find(
        ([, propValue]) => propValue.attribute === attributeKey
      );
      if (!property) {
        throw new Error(
          `${attributeKey} is not a valid attribute for nextVideo`
        );
      }
      this[property[0]] = property[1].type.fromString(attributeValue);
    });
  }

  toJSON() {
    const { staticProperties, ...rest } = this;
    return JSON.stringify(
      Object.entries(rest).reduce((acc, [key, value]) => {
        acc[staticProperties[key].attribute] =
          staticProperties[key].type.toString(value);
        return acc;
      }, {})
    );
  }
}

export class AspectRatio {
  constructor(aspectRatio) {
    if (typeof aspectRatio !== 'string') {
      throw new Error('aspectRatio must be a string');
    }
    if (aspectRatio.indexOf(':') === -1) {
      throw new Error('aspectRatio must contain a separator ":"');
    }
    const [width, height] = aspectRatio.split(':');
    this.width = Number(width);
    this.height = Number(height);
    if (Number.isNaN(this.width) || Number.isNaN(this.height)) {
      throw new Error('aspectRatio must contain valid numbers');
    }
  }

  toStyle() {
    return `--brick-player-ar:${this.width}/${this.height};`;
  }

  toString() {
    return `${this.width}:${this.height}`;
  }
}

export const types = {
  AspectRatio: {
    validate: (value) => {
      if (!value) {
        return true;
      }
      if (value instanceof AspectRatio) {
        return true;
      }
      return !!new AspectRatio(value);
    },
    fromString: (value) => (value ? new AspectRatio(value) : null),
    toString: (aspectRatio) => (aspectRatio ? aspectRatio.toString() : ''),
  },
  Boolean: {
    validate: (value) => typeof value === 'boolean',
    fromString: (value) =>
      value === 'true' ? true : value === 'false' ? false : undefined,
    toString: (value) => (typeof value === 'boolean' ? String(value) : ''),
  },
  Autoplay: {
    validate: (value) => {
      return ['undefined', ...validAutoplayValues].includes(String(value));
    },
    fromString: (value) => {
      return (
        autoplayOptions[value] ??
        autoplayOptions[inversedAutoplayOptions[value]]
      );
    },
    toString: (value) => {
      return String(inversedAutoplayOptions[value] ?? value);
    },
  },
  Float: {
    validate: (value) => typeof value === 'number',
    fromString: (value) => parseFloat(value),
    toString: (value) => value.toString(),
  },
  Image: {
    validate: (value) => {
      if ([null, undefined].includes(value)) return true;
      if (typeof value === 'string' && value === '') return true;
      if (value instanceof URL) {
        if (
          value.hostname.endsWith('api.no') ||
          value.hostname.endsWith('lwcdn.com')
        )
          return true;
      }
      return false;
    },
    fromString: (value) => {
      if (value === null) {
        return undefined;
      }
      if (['', undefined].includes(value)) {
        return '';
      }
      return new URL(value);
    },
    toString: (value) => value?.toString(),
  },
  NextVideo: {
    validate: (value, staticProperties) => {
      if (!value) {
        return true;
      }
      if (value instanceof NextVideo) {
        return true;
      }
      return !!new NextVideo(value, staticProperties);
    },
    fromString: (value, staticProperties) =>
      value ? new NextVideo(JSON.parse(value), staticProperties) : null,
    toString: (nextVideo) => (nextVideo ? nextVideo.toJSON() : ''),
  },
  String: {
    validate: (value) => typeof value === 'string',
    fromString: (value) => value,
    toString: (value) => value,
  },
  // type introduced to avoid breaking change
  // for later: require playerId to either be set with a value or not at all
  // current implementations allows an empty string
  PlayerId: {
    validate: (value) => typeof value === 'string',
    fromString: (value) => {
      if (value === '') {
        return DEFAULT_PLAYER_ID;
      }
      return value;
    },
    toString: (value) => value,
  },
  UiFlags: {
    validate: (value) => typeof value === 'number',
    fromString: (value) => parseUiOverrides(value),
    toString: (value) => toUiOverrides(value),
  },
  StartQuality: {
    validate: (value) => typeof value === 'number',
    fromString: (value) => (value ? parseStartQuality(value) : null),
    toString: (value) => toStartQuality(value),
  },
};
